var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-factory-check" }, [
    _c("img", {
      class: { "icon-goods": true, "xfm-img": _vm.ryType == 17 },
      attrs: { src: _vm.ossHost + _vm.imgUrl, alt: "" }
    }),
    _c(
      "div",
      { class: { "check-info-head": true, "xfm-head": _vm.ryType == 17 } },
      [
        _c("div", { staticClass: "device-number" }, [
          _vm._v(_vm._s(_vm.uniqueId))
        ]),
        _vm.ryType != 17
          ? _c("div", { staticClass: "device-type" }, [
              _vm._v(_vm._s(_vm.deviceName || "溶氧检测仪"))
            ])
          : _c("div", { staticClass: "device-type" }, [
              _vm._v("溶氧仪可视基础版")
            ])
      ]
    ),
    _c("div", { staticClass: "check-box" }, [
      _vm.ryType != 17
        ? _c("div", { staticClass: "check-body" }, [
            _c("div", { staticClass: "check-title" }, [
              _vm._v("质检列表： "),
              _c(
                "div",
                {
                  class: { "refresh-btn": true, disabled: _vm.loadingData },
                  on: { click: _vm.refreshSignal }
                },
                [_vm._v("重新获取")]
              )
            ]),
            _c("div", { staticClass: "content-item" }, [
              _c("div", { staticClass: "content-item-label" }, [
                _vm._v("信号强度已超过18 "),
                _vm.signal
                  ? _c("b", [_vm._v("(" + _vm._s(_vm.signal) + ")")])
                  : _vm._e()
              ]),
              _c(
                "div",
                { staticClass: "content-item-value" },
                [
                  _vm.loadingData
                    ? _c("Loading", {
                        attrs: {
                          size: "24",
                          type: "spinner",
                          color: _vm.themeColor
                        }
                      })
                    : _vm._e(),
                  !_vm.signalPass && !_vm.loadingData
                    ? _c("Icon", { attrs: { name: "close" } })
                    : _vm._e(),
                  _vm.signalPass && !_vm.loadingData
                    ? _c("Icon", { attrs: { name: "passed" } })
                    : _vm._e()
                ],
                1
              )
            ]),
            _c("div", { staticClass: "content-item" }, [
              _c("div", { staticClass: "content-item-label" }, [
                _vm._v("探头质检"),
                _vm.sensorPass
                  ? _c("b", [_vm._v("合格")])
                  : _c("span", [_vm._v("不合格")]),
                _vm._v(" "),
                _vm.sensorErrText
                  ? _c("span", [_vm._v("(" + _vm._s(_vm.sensorErrText) + ")")])
                  : _vm._e()
              ]),
              _c(
                "div",
                { staticClass: "content-item-value" },
                [
                  _vm.loadingData
                    ? _c("Loading", {
                        attrs: {
                          size: "24",
                          type: "spinner",
                          color: _vm.themeColor
                        }
                      })
                    : _vm._e(),
                  !_vm.sensorPass && !_vm.loadingData
                    ? _c("Icon", { attrs: { name: "close" } })
                    : _vm._e(),
                  _vm.sensorPass && !_vm.loadingData
                    ? _c("Icon", { attrs: { name: "passed" } })
                    : _vm._e()
                ],
                1
              )
            ]),
            _c("div", { staticClass: "content-item" }, [
              _c("div", { staticClass: "content-item-label" }, [
                _vm._v(_vm._s(_vm.warningList[0].text))
              ]),
              _c(
                "div",
                { staticClass: "content-item-value" },
                [
                  _vm.loadingData
                    ? _c("Loading", {
                        attrs: {
                          size: "24",
                          type: "spinner",
                          color: _vm.themeColor
                        }
                      })
                    : _vm._e(),
                  !_vm.warningList[0].pass && !_vm.loadingData
                    ? _c("Icon", { attrs: { name: "close" } })
                    : _vm._e(),
                  _vm.warningList[0].pass && !_vm.loadingData
                    ? _c("Icon", { attrs: { name: "passed" } })
                    : _vm._e()
                ],
                1
              )
            ]),
            _c("div", { staticClass: "content-item" }, [
              _c(
                "div",
                { staticClass: "content-item-label" },
                [
                  _vm._v("30分钟内主机正常充排气 "),
                  _vm.adErrText
                    ? _c("span", [_vm._v(_vm._s(_vm.adErrText))])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.pumpStatus
                    ? _c("span", [_vm._v("(气泵已关闭)")])
                    : _vm._e(),
                  _vm.pumpStatus
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "mini",
                            loading: _vm.updatePumpStatusLoading
                          },
                          on: { click: _vm.onclickUpdatePumpBtn }
                        },
                        [_vm._v("打开气泵")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "content-item-value" },
                [
                  _vm.loadingData
                    ? _c("Loading", {
                        attrs: {
                          size: "24",
                          type: "spinner",
                          color: _vm.themeColor
                        }
                      })
                    : _vm._e(),
                  !_vm.motorPass && !_vm.loadingData
                    ? _c("Icon", { attrs: { name: "close" } })
                    : _vm._e(),
                  _vm.motorPass && !_vm.loadingData
                    ? _c("Icon", { attrs: { name: "passed" } })
                    : _vm._e()
                ],
                1
              )
            ]),
            !_vm.notPumpCheck
              ? _c("div", { staticClass: "content-item" }, [
                  _c(
                    "div",
                    { staticClass: "content-item-label" },
                    [
                      _vm._v("通过气路气路检测"),
                      !_vm.pumpPass && !_vm.pumpLoad
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                size: "mini",
                                loading: _vm.pumpLoad
                              },
                              on: { click: _vm.onClickShowStartCheckModalBtn }
                            },
                            [_vm._v("重检")]
                          )
                        : _vm._e(),
                      !_vm.pumpPass && !_vm.pumpLoad && _vm.pumpCheckErrorText
                        ? _c("span", { staticClass: "error-text" }, [
                            _vm._v("(" + _vm._s(_vm.pumpCheckErrorText) + ")")
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "content-item-value" },
                    [
                      _vm.pumpLoad
                        ? _c("Loading", {
                            attrs: {
                              size: "24",
                              type: "spinner",
                              color: _vm.themeColor
                            }
                          })
                        : _vm._e(),
                      !_vm.pumpPass && !_vm.pumpLoad
                        ? _c("Icon", { attrs: { name: "close" } })
                        : _vm._e(),
                      _vm.pumpPass && !_vm.pumpLoad
                        ? _c("Icon", { attrs: { name: "passed" } })
                        : _vm._e()
                    ],
                    1
                  )
                ])
              : _c("div", { staticClass: "content-item disable" }, [
                  _c("div", { staticClass: "content-item-label" }, [
                    _vm._v("通过气路气路检测")
                  ]),
                  _c("div", { staticClass: "content-item-value" }, [
                    _vm._v("无需检测")
                  ])
                ])
          ])
        : _vm._e(),
      _vm.ryType != 17
        ? _c("div", { staticClass: "check-box" }, [
            _c("div", { staticClass: "check-body" }, [
              _c("div", { staticClass: "content-item" }, [
                _c("div", { staticClass: "content-item-label" }, [
                  _vm._v("不含压力开关,无需进行气路检测")
                ]),
                _c(
                  "div",
                  { staticClass: "content-item-value" },
                  [
                    _c("el-switch", {
                      attrs: {
                        "active-color": "#209A56",
                        "inactive-color": "#bbb"
                      },
                      on: {
                        click: function($event) {
                          _vm.notPumpCheck = !_vm.notPumpCheck
                        }
                      },
                      model: {
                        value: _vm.notPumpCheck,
                        callback: function($$v) {
                          _vm.notPumpCheck = $$v
                        },
                        expression: "notPumpCheck"
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        : _vm._e(),
      _vm.ryType == 17
        ? _c("div", { staticClass: "check-body" }, [
            _c("div", { staticClass: "check-title" }, [
              _vm._v("质检列表： "),
              _c(
                "div",
                {
                  class: { "refresh-btn": true, disabled: _vm.loadingData },
                  on: { click: _vm.refreshSignal }
                },
                [_vm._v("重新获取")]
              )
            ]),
            _c("div", { staticClass: "content-item" }, [
              _c("div", { staticClass: "content-item-label" }, [
                _vm._v("信号强度已超过18 "),
                _vm.signal
                  ? _c("b", [_vm._v("(" + _vm._s(_vm.signal) + ")")])
                  : _vm._e()
              ]),
              _c(
                "div",
                { staticClass: "content-item-value" },
                [
                  _vm.loadingData
                    ? _c("Loading", {
                        attrs: {
                          size: "24",
                          type: "spinner",
                          color: _vm.themeColor
                        }
                      })
                    : _vm._e(),
                  !_vm.signalPass && !_vm.loadingData
                    ? _c("Icon", { attrs: { name: "close" } })
                    : _vm._e(),
                  _vm.signalPass && !_vm.loadingData
                    ? _c("Icon", { attrs: { name: "passed" } })
                    : _vm._e()
                ],
                1
              )
            ]),
            _c("div", { staticClass: "content-item" }, [
              _c("div", { staticClass: "content-item-label" }, [
                _vm._v("探头空气饱和氧偏差小于10% ")
              ]),
              _c(
                "div",
                { staticClass: "content-item-value" },
                [
                  _vm.loadingData
                    ? _c("Loading", {
                        attrs: {
                          size: "24",
                          type: "spinner",
                          color: _vm.themeColor
                        }
                      })
                    : _vm._e(),
                  !_vm.fullOxyPass && !_vm.loadingData
                    ? _c("Icon", { attrs: { name: "close" } })
                    : _vm._e(),
                  _vm.fullOxyPass && !_vm.loadingData
                    ? _c("Icon", { attrs: { name: "passed" } })
                    : _vm._e()
                ],
                1
              )
            ]),
            _c("div", { staticClass: "content-item" }, [
              _c("div", { staticClass: "content-item-label" }, [
                _vm._v("探头零氧小于0.3")
              ]),
              _c(
                "div",
                { staticClass: "content-item-value" },
                [
                  _vm.loadingData
                    ? _c("Loading", {
                        attrs: {
                          size: "24",
                          type: "spinner",
                          color: _vm.themeColor
                        }
                      })
                    : _vm._e(),
                  !_vm.zeroOxyPass && !_vm.loadingData
                    ? _c("Icon", { attrs: { name: "close" } })
                    : _vm._e(),
                  _vm.zeroOxyPass && !_vm.loadingData
                    ? _c("Icon", { attrs: { name: "passed" } })
                    : _vm._e()
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ]),
    _vm.ryType != 17
      ? _c("div", { staticClass: "footer" }, [
          _vm.notPumpCheck
            ? _c(
                "div",
                {
                  class: {
                    btn: true,
                    pass:
                      _vm.signalPass &&
                      _vm.motorPass &&
                      !_vm.errorStatus &&
                      _vm.sensorPass
                  },
                  on: { click: _vm.passAction }
                },
                [_vm._v("质检通过 ")]
              )
            : _c(
                "div",
                {
                  class: {
                    btn: true,
                    pass:
                      _vm.signalPass &&
                      _vm.pumpPass &&
                      _vm.warningList[0].pass &&
                      _vm.motorPass &&
                      !_vm.errorStatus &&
                      _vm.sensorPass
                  },
                  on: { click: _vm.passAction }
                },
                [_vm._v(" 质检通过 ")]
              )
        ])
      : _c("div", { staticClass: "footer" }, [
          _c(
            "div",
            {
              class: {
                btn: true,
                pass: _vm.signalPass && _vm.fullOxyPass && _vm.zeroOxyPass
              },
              on: { click: _vm.passAction }
            },
            [_vm._v(" 质检通过 ")]
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }